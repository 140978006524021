<template>
  <t-split-screen wide centered>
    <div>
      <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <h1>{{ $t('pages.employee.register.heading') }}</h1>
        <p class="text--grey">
          {{ $t('pages.employee.register.lead') }}
        </p>

        <t-form class="mt-5" @submit.native.prevent="handleSubmit(submit)">
          <t-grid>
            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="firstname"
                  :name="$t('fields.first_name')"
                  :label="$t('fields.first_name')"
                  rules="required"
                >
                  <t-input
                    v-model="form.firstname"
                    type="text"
                    :placeholder="$t('fields.first_name')"
                  >
                    <t-icon slot="right" :icon="['fas', 'user']" />
                  </t-input>
                </t-validation-wrapper>
              </t-grid-cell>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="lastname"
                  :name="$t('fields.last_name')"
                  :label="$t('fields.last_name')"
                  rules="required"
                >
                  <t-input
                    v-model="form.lastname"
                    type="text"
                    :placeholder="$t('fields.last_name')"
                  >
                    <t-icon slot="right" :icon="['fas', 'user']" />
                  </t-input>
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>
          </t-grid>

          <t-validation-wrapper
            vid="email"
            :name="$t('fields.email_address')"
            rules="required|email"
          >
            <t-checked-email v-model="form.email" />
          </t-validation-wrapper>

          <t-validation-wrapper
            vid="password"
            :name="$t('fields.password.password')"
            rules="required|min:8|password"
          >
            <password-input
              v-model="form.password"
              :placeholder="`${$t('fields.password.password')}...`"
            />
          </t-validation-wrapper>

          <t-validation-wrapper
            vid="confirmation"
            :name="$t('fields.password.confirmation')"
            rules="required|confirmed:password"
          >
            <password-input
              v-model="confirmation"
              :placeholder="`${$t('fields.password.confirmation')}...`"
            />
          </t-validation-wrapper>

          <t-validation-wrapper vid="gender_id" :name="$t('fields.gender')" rules="required|string">
            <gender-select v-model="form.gender" />
          </t-validation-wrapper>

          <t-validation-wrapper
            :name="$t('fields.privacy_policy')"
            :rules="{ required: { allowFalse: false } }"
            no-label
          >
            <t-checkbox v-model="privacyAccepted">
              <i18n path="fields.privacy_policy_accept" tag="span">
                <a :href="$config.public.service.employee_privacy_url" target="_blank">{{
                  $t('fields.privacy_policy')
                }}</a>
              </i18n>
            </t-checkbox>
          </t-validation-wrapper>

          <t-validation-wrapper
            :name="$t('fields.terms_of_service')"
            :rules="{ required: { allowFalse: false } }"
            no-label
          >
            <t-checkbox v-model="tosAccepted">
              <i18n path="fields.terms_of_service_accept" tag="span">
                <a :href="$config.public.service.employee_tos_url" target="_blank">{{
                  $t('fields.terms_of_service')
                }}</a>
              </i18n>
            </t-checkbox>
          </t-validation-wrapper>

          <t-form-element>
            <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
              {{ $t('global.actions.register') }}
            </t-button-loading>
          </t-form-element>
        </t-form>
      </validation-observer>
    </div>

    <p class="text--centered">
      {{ $t('pages.employee.register.existing_account_lead') }}
      <nuxt-link :to="{ name: $RouteEnum.LOGIN }">
        {{ $t('global.actions.login') }}
      </nuxt-link>
    </p>

    <dashboard-image slot="secondary" name="register-applicant" />
  </t-split-screen>
</template>

<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import TCheckedEmail from '~/components/Fields/TCheckedEmail';
import DashboardImage from '~/components/DashboardImage.vue';
import PasswordInput from '~/components/PasswordInput.vue';
import GenderSelect from '~/components/EnumSelect/GenderSelect.vue';
import UserTypeEnum from '~/enums/UserTypeEnum';
import Invitation from '~/models/Invitation';
import InvitationStateEnum from '~/enums/InvitationStateEnum';

export default Vue.extend({
  components: {
    TValidationWrapper,
    TCheckedEmail,
    DashboardImage,
    GenderSelect,
    PasswordInput,
  },

  layout: 'standalone',
  middleware: 'guest',

  data() {
    return {
      form: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        gender: 'male',
        invitation_token: null,
        timezone: this.$dayjs.tz.guess(),
      },

      confirmation: null,
      privacyAccepted: false,
      tosAccepted: false,
      status: null,
      loading: false,
    };
  },

  async fetch() {
    const token = this.$route.query?.token;

    if (!token) {
      await this.$notify.error(this.$t('notifications.invitation.required'));
      await this.$router.pushExpectRedirect({ name: this.$RouteEnum.LOGIN });
      return;
    }

    try {
      const invitation = await Invitation.$findByToken(token);

      if (invitation.state !== InvitationStateEnum.PENDING) {
        const message = this.getMessageForInvalidState(invitation.state);
        await this.$notify.error(message);
        return;
      }

      this.form.firstname = invitation?.meta?.firstname;
      this.form.lastname = invitation?.meta?.lastname;
      this.form.email = invitation?.email;
      this.form.gender = invitation?.meta?.gender;
      this.form.invitation_token = token;
    } catch (e) {
      if (e.response.status === 404) {
        this.$notify.warning(this.$t('notifications.invitation.no_association'));
        return;
      }
      this.$axios.handleError(e);
    }
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$auth.register({
          userType: UserTypeEnum.EMPLOYEE,
          payload: this.form,
        });
        await this.$router.pushExpectRedirect({ name: this.$auth.getOnboardingRoute() });
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    getMessageForInvalidState(state) {
      switch (state) {
        case InvitationStateEnum.ACCEPTED:
          return this.$t('notifications.invitation.already_accepted');
        case InvitationStateEnum.EXPIRED:
          return this.$t('notifications.invitation.expired');
      }

      return '';
    },
  },
});
</script>
